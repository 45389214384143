@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('author.css');

body {
	font-family: Author-Variable, sans-serif;
	font-feature-settings:
		'pnum' on,
		'lnum' on;
}

.hide-for-mobile-override {
	& > *:nth-child(1) {
		display: none;
	}

	/* tailwind md: */
	@media (max-width: 768px) {
		& > * {
			display: none;
		}
	}
}
